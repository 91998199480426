import { ElMessage, ElMessageBox, ElNotification } from "element-plus";

export default {
  // 消息提示
  msg(text: any) {
    ElMessage(text);
  },

  //   // 错误消息
  msgError(text: any) {
    ElMessage.error(text);
  },
  // 成功消息
  msgSuccess(text: any) {
    ElMessage({
      message: text,
      type: "success",
    });
  },

  // 警告消息
  msgWarning(text: any) {
    ElMessage({
      message: text,
      type: "warning",
    });
  },

  // 弹出提示
  alert(text: any) {
    ElMessageBox.alert(text, "系统提示");
  },

  // 错误提示
  alertError(content: any) {
    ElMessageBox.alert(content, "系统提示", { type: "error" });
  },

  // 成功提示
  alertSuccess(content: any) {
    ElMessageBox.alert(content, "系统提示", { type: "success" });
  },

  // 警告提示
  alertWarning(content: any) {
    ElMessageBox.alert(content, "系统提示", { type: "warning" });
  },

  // 通知提示
  notify(content: any) {
    ElNotification.info(content);
  },
  // 错误通知
  notifyError(content: any) {
    ElNotification.error(content);
  },

  // 成功通知
  notifySuccess(content: any) {
    ElNotification.success(content);
  },
  // 警告通知
  notifyWarning(content: any) {
    ElNotification.warning(content);
  },
  // 确认窗体
  confirm(content: any) {
    return ElMessageBox.confirm(content, "系统提示", {
      confirmButtonText: "确定",
      cancelButtonText: "取消",
      type: "warning",
    });
  },
  // 提交内容
  prompt(content: any) {
    return ElMessageBox.prompt(content, "系统提示", {
      confirmButtonText: "确定",
      cancelButtonText: "取消",
      type: "warning",
    });
  },

  filterList(list: any, id: any) {
    if (list.length) {
      return list.filter((item: any) => item.value == id)[0].label;
    }
  },
};

// export default {
//   // 打开遮罩层
//   loading(content) {
//     loadingInstance = Loading.service({
//       lock: true,
//       text: content,
//       spinner: "el-icon-loading",
//       background: "rgba(0, 0, 0, 0.7)",
//     });
//   },
//   // 关闭遮罩层
//   closeLoading() {
//     loadingInstance.close();
//   },
// };
